@import "../variables.scss";

.resource-cards {
  height: 40px;
  display: flex;
  gap: 6px;

  margin-bottom: $sm-gutter;

  .card {
    border-radius: 4px;
    div {
      background: #151313;
      color: white;

      width: 21px;
      height: 36px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 0.8rem;
      font-weight: bold;
      position: relative;
    }
  }
  .wood-cards {
    background: $wood;
  }
  .brick-cards {
    background: $brick;
  }
  .wheat-cards {
    background: $wheat;
  }
  .sheep-cards {
    background: $darker-sheep;
  }
  .ore-cards {
    background: $ore;
  }
  .dev-cards {
    background: purple;
    div {
      font-size: 0.7rem;
    }
  }
}

.center-text {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 25px;
  height: 40px;
}

.player-state-box {
  padding: $sm-gutter;

  max-width: $sm-breakpoint;
  margin: 0 auto;
  width: 100%;

  .scores {
    display: flex;
    justify-content: space-between;
  }

  .num-knights,
  .num-roads,
  .victory-points {
    flex-direction: column;
    width: 50px;
    height: 40px;
  }

  .bold {
    font-weight: bold;
  }
}
