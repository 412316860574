@import "../variables.scss";

.RED {
  background: $red;
}
.BLUE {
  background: $blue;
}
.ORANGE {
  background: $orange;
}
.WHITE {
  background: $white;
}
.RED.foreground {
  color: $red;
  background: #00000000;
}
.BLUE.foreground {
  color: $blue;
  background: #00000000;
}
.ORANGE.foreground {
  color: $orange;
  background: #00000000;
}
.WHITE.foreground {
  color: white;
  background: #00000000;
}

// ===== Number Tokens
.number-token,
.port {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;

  position: relative;
}

@media screen and (min-width: $sm-breakpoint) {
  .number-token,
  .port {
    font-size: 1.3rem;
  }
}

.MuiPaper-elevation3.number-token {
  background: $dark-gray;
  color: white;
}

.MuiPaper-elevation3.robber {
  background: #6b6b6b;

  position: absolute;
}

// ===== Tiles
.tile {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

// ===== Edges
.edge {
  position: absolute;
  display: flex;
  justify-content: center;

  height: 5px; // stroke
}
.road {
  position: absolute;
  border: 1px solid $dark-gray;
  width: 100%;
  height: 100%;
  border-radius: 2px;
}
@media screen and (min-width: $sm-breakpoint) {
  .edge {
    height: 12px; // stroke
  }
}

// ===== Nodes
.node {
  position: absolute;
  height: 10px;
  width: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.settlement {
  width: 80%;
  height: 80%;
}
.city {
  width: 100%;
  height: 100%;
}
.settlement,
.city {
  border-radius: 4px; // mimic Paper
  border: 2px solid $dark-gray;
}

.BLUE.city {
  border: 4px solid $blue;
  background: $dark-gray;
}
.RED.city {
  border: 4px solid $red;
  background: $dark-gray;
}
.ORANGE.city {
  border: 4px solid $orange;
  background: $dark-gray;
}
.WHITE.city {
  border: 4px solid $white;
  background: $dark-gray;
}

// Taken from https://codepen.io/peeke/pen/BjxXZa.
.pulse {
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.edge .pulse {
  width: 90%;
  height: 90%;
}

.node .pulse {
  width: 75%;
  height: 75%;
}
