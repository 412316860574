// Taken from https://material-ui.com/customization/breakpoints/
$sm-breakpoint: 600px;
$md-breakpoint: 960px;

$sm-gutter: 10px;

$black: #030000;
$dark-gray: #151313;

$blue: #2278ff;
$red: #fe0400;
$orange: #ffa500;
$white: white;

$wood: #119822;
$brick: #cc0300;
$wheat: #fac60c;
$sheep: #87ff65;
$darker-sheep: #72cf57;
$ore: #616166;
